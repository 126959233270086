.root {
  @apply flex items-center justify-center text-center transition duration-150 ease-in-out border border-transparent rounded-md focus:outline-none active:outline-none;
}

/********** SIZE **********/

.root[data-size="small"] {
  @apply h-8 px-3 text-sm font-medium;
}

.root[data-size="medium"] {
  @apply h-11 px-3.5 text-sm font-medium md:text-input;
}

.root[data-size="large"] {
  @apply px-4 text-lg font-semibold h-14 md:text-xl;
}

/********** VARIANT: PRIMARY **********/

.root[data-variant="primary"][data-type="solid"] {
  @apply text-white bg-primary-solid;
}

.root[data-variant="primary"][data-type="soft"] {
  @apply bg-primary-bg-hover text-primary-solid;
}

.root[data-variant="primary"][data-type="surface"] {
  @apply border border-primary-border bg-primary-bg-subtle text-primary-solid;
}

.root[data-variant="primary"][data-type="outline"] {
  @apply bg-transparent border border-primary-border text-primary-solid;
}

.root[data-variant="primary"][data-type="ghost"] {
  @apply text-primary-solid;
}

/********** VARIANT: GRAY **********/

.root[data-variant="gray"][data-type="solid"] {
  @apply bg-fg-text-contrast text-bg-base;
}

.root[data-variant="gray"][data-type="soft"] {
  @apply bg-bg-bg-hover text-fg-text-contrast;
}

.root[data-variant="gray"][data-type="surface"] {
  @apply border border-fg-border bg-bg-bg-subtle text-fg-text-contrast;
}

.root[data-variant="gray"][data-type="outline"] {
  @apply bg-transparent border border-fg-border text-fg-text-contrast;
}

.root[data-variant="gray"][data-type="ghost"] {
  @apply text-fg-text-contrast;
}

/********** VARIANT: PRIMARY, HOVER **********/

.root[data-variant="primary"][data-type="solid"]:hover {
  @apply bg-primary-solid-hover;
}

.root[data-variant="primary"][data-type="soft"]:hover {
  @apply bg-primary-bg-subtle;
}

.root[data-variant="primary"][data-type="surface"]:hover {
  @apply border-primary-border-hover;
}

.root[data-variant="primary"][data-type="outline"]:hover {
  @apply border-primary-border bg-primary-bg;
}

.root[data-variant="primary"][data-type="ghost"]:hover {
  @apply bg-primary-bg-hover;
}

/********** VARIANT: GRAY, HOVER **********/

.root[data-variant="gray"][data-type="solid"]:hover {
  @apply bg-fg-default;
}

.root[data-variant="gray"][data-type="soft"]:hover {
  @apply bg-bg-bg-active;
}

.root[data-variant="gray"][data-type="surface"]:hover {
  @apply border-fg-border-hover;
}

.root[data-variant="gray"][data-type="outline"]:hover {
  @apply border-fg-border bg-bg-bg;
}

.root[data-variant="gray"][data-type="ghost"]:hover {
  @apply bg-bg-bg-hover;
}

/********** PRIMARY DISABLED **********/

.root[data-variant="primary"][data-type="solid"]:disabled,
.root[data-variant="primary"][data-type="soft"]:disabled,
.root[data-variant="gray"][data-type="solid"]:disabled,
.root[data-variant="gray"][data-type="soft"]:disabled {
  @apply bg-bg-bg;
}

.root[data-variant="primary"][data-type="surface"]:disabled,
.root[data-variant="gray"][data-type="surface"]:disabled  {
  @apply border-fg-line bg-bg-bg-subtle;
}

.root[data-variant="primary"][data-type="outline"]:disabled,
.root[data-variant="gray"][data-type="outline"]:disabled {
  @apply bg-transparent border-fg-line;
}

/********** COMMON **********/

.root[data-variant="primary"][data-type="ghost"],
.root[data-variant="gray"][data-type="ghost"],
.root[data-variant="primary"][data-type="ghost"]:disabled,
.root[data-variant="gray"][data-type="ghost"]:disabled  {
  @apply bg-transparent;
}

.root[data-variant="primary"]:hover,
.root[data-variant="gray"]:hover {
  @apply cursor-pointer;
}

.root[data-variant="primary"]:disabled,
.root[data-variant="gray"]:disabled  {
  @apply cursor-default text-fg-border-hover;
}
