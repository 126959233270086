@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~react-toastify/dist/ReactToastify.css";

:root {
  --bg-default: #fff;
  --bg-base: #fcfcfc;
  --bg-bg-subtle: #f8f8f8;
  --bg-bg: #f3f3f3;
  --bg-bg-hover: #ededed;
  --bg-bg-active: #e8e8e8;
  --fg-line: #e2e2e2;
  --fg-border: #dbdbdb;
  --fg-border-hover: #c7c7c7;
  --fg-solid: #8f8f8f;
  --fg-solid-hover: #858585;
  --fg-text: #6f6f6f;
  --fg-text-contrast: #171717;
  --fg-default: #000;

  --primary-base: #f5f3f2;
  --primary-bg-subtle: #ede6e0;
  --primary-bg: #e6ded7;
  --primary-bg-hover: #d1c4b8;
  --primary-bg-active: #b8a597;
  --primary-line: #b19d90;
  --primary-border: #b19887;
  --primary-border-hover: #8c7462;
  --primary-solid: #5c3520;
  --primary-solid-hover: #412617;
  --primary-text: #331d12;
  --primary-text-contrast: #27160d;

  --secondary-base: #fcfcfc;
  --secondary-bg-subtle: #fafafb;
  --secondary-bg: #f7f7f8;
  --secondary-bg-hover: #f3f3f4;
  --secondary-bg-active: #ebebed;
  --secondary-line: #e6e6e8;
  --secondary-border: #dad9dd;
  --secondary-border-hover: #c9c8cd;
  --secondary-solid: #afadb5;
  --secondary-solid-hover: #7c7b81;
  --secondary-text: #605f64;
  --secondary-text-contrast: #4a494c;

  --success-base: #fbfefc;
  --success-bg-subtle: #f2fcf5;
  --success-bg: #e9f9ee;
  --success-bg-hover: #ddf3e4;
  --success-bg-active: #ccebd7;
  --success-line: #b4dfc4;
  --success-border: #92ceac;
  --success-border-hover: #5bb98c;
  --success-solid: #30a46c;
  --success-solid-hover: #299764;
  --success-text: #18794e;
  --success-text-contrast: #153226;

  --warning-base: #fdfdf9;
  --warning-bg-subtle: #fffce8;
  --warning-bg: #fffbd1;
  --warning-bg-hover: #fff8bb;
  --warning-bg-active: #fef2a4;
  --warning-line: #f9e68c;
  --warning-border: #efd36c;
  --warning-border-hover: #ebbc00;
  --warning-solid: #f5d90a;
  --warning-solid-hover: #f7ce00;
  --warning-text: #946800;
  --warning-text-contrast: #35290f;

  --alert-base: #fffcfc;
  --alert-bg-subtle: #fff8f8;
  --alert-bg: #ffefef;
  --alert-bg-hover: #ffe5e5;
  --alert-bg-active: #fdd8d8;
  --alert-line: #f9c6c6;
  --alert-border: #f3aeaf;
  --alert-border-hover: #eb9091;
  --alert-solid: #e5484d;
  --alert-solid-hover: #dc3d43;
  --alert-text: #cd2b31;
  --alert-text-contrast: #381316;

  --info-base: #fafdfe;
  --info-bg-subtle: #f2fcfd;
  --info-bg: #e7f9fb;
  --info-bg-hover: #d8f3f6;
  --info-bg-active: #c4eaef;
  --info-line: #aadee6;
  --info-border: #84cdda;
  --info-border-hover: #3db9cf;
  --info-solid: #05a2c2;
  --info-solid-hover: #0894b3;
  --info-text: #0c7792;
  --info-text-contrast: #04313c;

  --overlay-base: hsla(0, 0%, 100%, 0);
  --overlay-bg-subtle: hsla(0, 0%, 100%, 0.013);
  --overlay-bg: hsla(0, 0%, 100%, 0.034);
  --overlay-bg-hover: hsla(0, 0%, 100%, 0.056);
  --overlay-bg-active: hsla(0, 0%, 100%, 0.086);
  --overlay-line: hsla(0, 0%, 100%, 0.124);
  --overlay-border: hsla(0, 0%, 100%, 0.176);
  --overlay-border-hover: hsla(0, 0%, 100%, 0.249);
  --overlay-solid: hsla(0, 0%, 100%, 0.386);
  --overlay-solid-hover: hsla(0, 0%, 100%, 0.446);
  --overlay-text: hsla(0, 0%, 100%, 0.592);
  --overlay-text-contrast: hsla(0, 0%, 100%, 0.923);

  --overlay-modal: #00000080;
}

body,
html {
  font-family: "Poppins", sans-serif;
}

.Toastify__toast-container--top-center {
  width: -moz-fit-content;
  width: fit-content;
}
.Toastify__close-button {
  align-self: center;
}

.Toastify__toast {
  min-height: 42px;
  border-radius: 0.5rem;
}

::-webkit-scrollbar {
  @apply w-[5px] h-[5px];
}

::-webkit-scrollbar-thumb {
  @apply rounded-lg border-b bg-primary-solid;
}

::-webkit-scrollbar-track {
  @apply rounded-lg bg-secondary-border;
}

::-webkit-scrollbar-thumb:horizontal {
  @apply h-[3px] rounded-lg;
}

.react-tel-input .form-control {
  height: 44px !important;
  border-radius: 0.375rem !important;
  width: 100% !important;
}

.react-tel-input .form-control,
.react-tel-input .flag-dropdown {
  border-color: #afadb5 !important;
}

.react-tel-input .selected-flag {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.react-tel-input .country-list {
  width: 500px !important;
}
